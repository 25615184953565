import React, { useState, useEffect } from "react";
import PageTitle from "../layouts/PageTitle";
import { MultiSelect } from "./MultiSelect";
import {
  getHosts,
  getUsers,
  pushNotification,
} from "../../services/AuthService";
import swal from "sweetalert";
import Spinner from "../loader/Spinner";
import Select from "react-select";

export default function Notification(props) {
  const [loader, setLoader] = useState(false);
  const [apiError, setApiError] = useState();
  let errorsObj = { title: "", body: "", type: "", selected: [] };
  const [errors, setErrors] = useState(errorsObj);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [type, setType] = useState("");
  const [selected, setSelected] = useState([]);
  const [hostSelected, setHostSelected] = useState([]);
  const [userList, setUserList] = useState([]);
  const [hostList, setHostList] = useState([]);
// console.log(type," selected type")
  const option = [
    { value: "Users", label: "Users" },
    { value: "Event Hosts", label: "Event Hosts" },
    { value: "Both", label: "Both" },
  ];

  const options = [
    ...userList?.map((item, i) => {
      return { value: item?._id, label: item?.name };
    }),
  ];
  const hostOptions = [
    ...hostList?.map((item, i) => {
      return { value: item?._id, label: item?.name };
    }),
  ];
  const totalIds = [...selected, ...hostSelected];
  // console.log(totalIds, "all ids")
  const ids = totalIds?.map((item, i) => {
    console.log(item?.value, "id...............");
    return item?.value;
  });

  function onSubmit(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (title === "") {
      errorObj.title = "Title is Required";
      error = true;
    }
    if (body === "") {
      errorObj.body = "Body is Required";
      error = true;
    }
    if (type === "") {
      errorObj.type = "Type is Required";
      error = true;
    }
    if (selected.length === 0) {
      errorObj.selected = "Please select an user";
      error = true;
      console.log(selected)
    }
    if (hostSelected.length === 0) {
      errorObj.hostSelected = "Please select an host";
      error = true;
      console.log(selected)
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader(true);
    pushNotification(title, body, ids)
      .then(() => {
        swal("Done!", "Notification Sent Successfully.", "success");
        setTitle("");
        setBody("");
        setType("");
        setSelected([]);
        setHostSelected([]);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error.response, "helooooooooo");
        if (error.response.data.statusCode === 400) {
          localStorage.clear("userDetails");
          props.history.push("/login");
        }
      });
  }

  useEffect(() => {
    setLoader(true);
    getUsers()
      .then((response) => {
        console.log(response, "user data response");
        setUserList(response.data.data.users);
        setLoader(false);
        console.log(response.data.data.users, " table data ");
      })
      .catch((error) => {
        console.log(error.response, "helooooooooo");
        if (error.response.data.statusCode === 400) {
          localStorage.clear("userDetails");
          props.history.push("/login");
        }
      });

    getHosts()
      .then((response) => {
        console.log(response, "host data response");
        setHostList(response.data.data.users);
        setLoader(false);
        console.log(response.data.data.users, " host data ");
      })
      .catch((error) => {
        console.log(error.response, "helooooooooo");
        if (error.response.data.statusCode === 400) {
          localStorage.clear("userDetails");
          props.history.push("/login");
        }
      });
  }, []);
  return (
    <div>
      <PageTitle activeMenu="Push Notifications" motherMenu="Notification" />
      <div className="col-lg-8 col-md-7 ">
        <div className="authincation-content text-black notification">
          <div className="mb-4">
            <h3 className="mb-1 font-w600 text-black ">Add Details</h3>
          </div>
          {apiError && (
            <div
              role="alert"
              className="fade alert-dismissible fade show alert alert-danger show"
            >
              {apiError}
            </div>
          )}
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">Title</strong>
              </label>
              <input
                type="text"
                className="form-control"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enter title"
                maxLength={100}
              />
              {errors.title && (
                <div className="text-danger fs-12">{errors.title}</div>
              )}
            </div>
            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">Body</strong>
              </label>
              <input
                type="text"
                className="form-control"
                value={body}
                onChange={(e) => setBody(e.target.value)}
                placeholder="Write something here.."
                maxLength={250}
              />
              {errors.body && (
                <div className="text-danger fs-12">{errors.body}</div>
              )}
            </div>
            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">Send To</strong>
              </label>
              <Select
                name="type"
                // className="user-select w-100 p-2 form-control"
                required
                // value={type}
                options={option}
                onChange={(e)=>setType(e.value)}
              
              />
              {/* <option value="" >
                  Select Option..
                </option>
                <option>Users</option>
                <option>Event Hosts</option>
                <option>Both</option>
              </select> */}
              {errors.type && (
                <div className="text-danger fs-12">{errors.type}</div>
              )}
            </div>
            {type === "Users" ? (
              <div className="form-group">
                <label className="mb-2 ">
                  <strong className="">Users</strong>
                </label>
                <MultiSelect
                  className="user-select"
                  options={options}
                  value={selected}
                  onChange={setSelected}
                  required
                />
                {errors.selected && (
                  <div className="text-danger fs-12">{errors.selected}</div>
                )}
              </div>
            ) : (
              ""
            )}
            {type === "Event Hosts" ? (
              <div className="form-group">
                <label className="mb-2 ">
                  <strong className="">Event Hosts</strong>
                </label>
                <MultiSelect
                  className="user-select"
                  options={hostOptions}
                  value={hostSelected}
                  onChange={setHostSelected}
                  required={true}
                />
                {errors.hostSelected && (
                  <div className="text-danger fs-12">{errors.hostSelected}</div>
                )}
              </div>
            ) : (
              ""
            )}
            {type === "Both" ? (
              <div>
                {" "}
                <div className="form-group">
                  <label className="mb-2 ">
                    <strong className="">Users</strong>
                  </label>
                  <MultiSelect
                    className="user-select"
                    options={options}
                    value={selected}
                    onChange={setSelected}
                    required
                  />
                  {errors.selected && (
                    <div className="text-danger fs-12">{errors.selected}</div>
                  )}
                </div>
                <div className="form-group">
                  <label className="mb-2 ">
                    <strong className="">Event Hosts</strong>
                  </label>
                  <MultiSelect
                    className="user-select"
                    options={hostOptions}
                    value={hostSelected}
                    onChange={setHostSelected}
                    required
                  />
                  {errors.hostSelected && (
                    <div className="text-danger fs-12">{errors.hostSelected}</div>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="text-center">
              <button type="submit" className="btn btn-primary btn-block">
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
      {loader && <Spinner />}
    </div>
  );
}
