import React from 'react'
import { Loader } from 'rsuite';
  
export default function Spinner() {
    return (
        <div style={{ margin: 200 }}>
            <Loader size="lg" backdrop ={true}
                center content="Loading..." />
        </div>
    );
}