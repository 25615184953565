import axios from "axios";
import swal from "sweetalert";
import { loginConfirmedAction, logout } from "../store/actions/AuthActions";

const baseUrl = "https://api.firstlook.events";

export function signUp(email, password) {
  //axios call

  const postData = {
    email,
    password,
    returnSecureToken: true,
  };

  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData
  );
}

export function login(email, password) {
  const postData = {
    email,
    password,
    // returnSecureToken: true,
  };

  return axios.post(`${baseUrl}/admin/login`, postData);
}
export function resetPassword(oldPassword, newPassword) {
  console.log(oldPassword, newPassword, "  in api function");
  const postData = {
    oldPassword,
    newPassword,
  };
  const data = localStorage.getItem("userDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  console.log(myHeaders, " headers response");
  return axios.put(`${baseUrl}/admin/changePassword`, postData, {
    headers: myHeaders,
  });
}

export function getUsers() {
  const data = localStorage.getItem("userDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  // const urlencoded = new URLSearchParams();
  // console.log(myHeaders, "table headers response");
  return axios.get(`${baseUrl}/getAllUser`, {
    headers: myHeaders,
  });
}
export function getAllUsers(currentPage, limit, search) {
  console.log(search, " search")
  const data = localStorage.getItem("userDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  // console.log(
  //   `${baseUrl}/getAllUser?page=${currentPage}&limit=${limit}&search=${search}`,
  //   "api path"
  // );
  return axios.get(
    `${baseUrl}/getAllUser?page=${currentPage}&limit=${limit}&search=${search}`,
    {
      headers: myHeaders,
    }
  );
}
export function blockUser(userId) {
  const postData = {
    userId,
  };
  const data = localStorage.getItem("userDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return axios.put(`${baseUrl}/admin/userblock`, postData, {
    headers: myHeaders,
  });
}
export function unblockUser(userId) {
  const postData = {
    userId,
  };
  const data = localStorage.getItem("userDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return axios.put(`${baseUrl}/admin/userUnblock`, postData, {
    headers: myHeaders,
  });
}
export function getHosts() {
  const data = localStorage.getItem("userDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  // const urlencoded = new URLSearchParams();
  // console.log(myHeaders, "table headers response");
  return axios.get(`${baseUrl}/seeHost`, {
    headers: myHeaders,
  });
}
export function getAllHosts(currentPage, limit,search) {
  const data = localStorage.getItem("userDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  // console.log(
  //   `${baseUrl}/getAllHost?page=${currentPage}&limit=${limit}&search=${search}`,
  //   "api path"
  // );
  return axios.get(
    `${baseUrl}/getAllHost?page=${currentPage}&limit=${limit}&search=${search}`,
    {
      headers: myHeaders,
    }
  );
}
export function getUserEvents(currentPage, limit) {
  const data = localStorage.getItem("userDetails");
  const id = localStorage.getItem("userId");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  console.log(
    `${baseUrl}/seeUserEvents?page=${currentPage}&limit=${limit}&userId=${id}`,
    "api path"
  );
  return axios.get(
    `${baseUrl}/seeUserEvents?page=${currentPage}&limit=${limit}&userId=${id}`,
    {
      headers: myHeaders,
    }
  );
}
export function getHostEvents(currentPage, limit) {
  const data = localStorage.getItem("userDetails");
  const id = localStorage.getItem("hostId");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  console.log(
    `${baseUrl}/seeHostEvents?page=${currentPage}&limit=${limit}&hostId=${id}`,
    "api path"
  );
  return axios.get(
    `${baseUrl}/seeHostEvents?page=${currentPage}&limit=${limit}&hostId=${id}`,
    {
      headers: myHeaders,
    }
  );
}
export function pushNotification(title, body, ids) {
  const data = localStorage.getItem("userDetails");
  const postData = {
    title,
    body,
    ids: ids ,
  };
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  console.log(postData, " post data");
  return axios.post(`${baseUrl}/pushNotification`, postData, {
    headers: myHeaders,
  });
}
export function formatError(errorResponse) {
  switch (errorResponse.error.message) {
    case "EMAIL_EXISTS":
      //return 'Email already exists';
      swal("Oops", "Email already exists", "error");
      break;
    case "EMAIL_NOT_FOUND":
      //return 'Email not found';
      swal("Oops", "Email not found", "error", { button: "Try Again!" });
      break;
    case "INVALID_PASSWORD":
      //return 'Invalid Password';
      swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "";
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  //   tokenDetails.expireDate = new Date(
  //     new Date().getTime() + tokenDetails.expiresIn * 1000
  //   );
  // localStorage.setItem("userId", userId);
  localStorage.setItem("userDetails", tokenDetails);
}
export function saveIdInLocalStorage(userId) {
  localStorage.setItem("userId", userId);
}
export function saveHostIdInLocalStorage(hostId) {
  localStorage.setItem("hostId", hostId);
}
export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

export function checkAutoLogin(dispatch, history) {
  // const tokenDetailsString = localStorage.getItem("userDetails");
  // let tokenDetails = "";
  // if (!tokenDetailsString) {
  //   dispatch(logout(history));
  //   return;
  // }

  // tokenDetails = JSON.parse(tokenDetailsString);
  // let expireDate = new Date(tokenDetails.expireDate);
  // let todaysDate = new Date();

  // if (todaysDate > expireDate) {
  //   dispatch(logout(history));
  //   return;
  // }
  // dispatch(loginConfirmedAction(tokenDetails));

  // const timer = expireDate.getTime() - todaysDate.getTime();
  // runLogoutTimer(dispatch, timer, history);
}
