import React, { useEffect, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import { Link } from "react-router-dom";
import { Dropdown, Card, Table, Col } from "react-bootstrap";
import {
  blockUser,
  getAllUsers,
  saveIdInLocalStorage,
  unblockUser,
} from "../../services/AuthService";
import ReactPaginate from "react-paginate";
import Spinner from "../loader/Spinner";
import swal from "sweetalert";
import moment from "moment";
export default function Users(props) {
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [apiError, setApiError] = useState("");
  const [search, setSearch] = useState("");
  const limit = 10;
  // console.log(search," search")
  function getTableData() {
    setLoader(true);
    getAllUsers(currentPage, limit, search)
      .then((response) => {
        setUsers(response.data.data.users);
        const total = response.data.data.total;
        setLoader(false);
        setPageCount(Math.ceil(total / limit));
        console.log(Math.ceil(total / limit), " page count");
        console.log(response.data.data.users, " table data ");
      })
      .catch((error) => {
        console.log(error.response, "helooooooooo");
        if (error.response.data.statusCode === 400) {
          localStorage.clear("userDetails");
          props.history.push("/login");
        }
      });
  }

  function onUnblock(userId) {
    console.log(userId, "user id");
    unblockUser(userId)
      .then((response) => {
        swal("User Unblocked Successfully.", { icon: "success" });
        getTableData();
      })
      .catch((error) => {
        // console.log(error.response.data.message ,"Login error")
        setApiError(error.response.data.message);
        if (error.response.data.statusCode === 400) {
          localStorage.clear("userDetails");
          props.history.push("/login");
        }
      });
  }
  function onBlock(userId) {
    console.log(userId, "user id");
    blockUser(userId)
      .then((response) => {
        swal("User Blocked Successfully.", { icon: "success" });
        getTableData();
      })
      .catch((error) => {
        // console.log(error.response.data.message ,"Login error")
        setApiError(error.response.data.message);
        if (error.response.data.statusCode === 400) {
          localStorage.clear("userDetails");
          props.history.push("/login");
        }
      });
  }
  useEffect(() => {
    getTableData();
  }, [currentPage]);

  useEffect(() => {
    if (search.length > 0) {
      getTableData();
    } else {
      getTableData();
    }
  }, [currentPage, search]);
  return (
    <div>
      <PageTitle activeMenu="Users List" motherMenu="Users" />
      <Col>
        <Card>
          <Card.Header>
            <div className="d-flex justify-content-between align-items-center">
              <div className="col-8" style={{ flexGrow: 1 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="input-group border bg-white input-group-sm"
                    style={{ borderRadius: "8px" }}
                  >
                    <input
                      style={{
                        paddingBottom: "25px",
                        paddingTop: "25px",
                        borderRadius: "10px",
                        fontSize: "14px",
                      }}
                      type="text"
                      // name="table_search"
                      className="form-control float-right border-0"
                      placeholder="Search"
                      // onKeyDown={(e) => {
                      //   console.log(e.key,"lenght");
                      //   if (e.key === "Enter") {
                      //     getTableData();
                      //     // setCurrentPage(0);
                      //     console.log(e.key,"lenght");
                      //   }
                      // }}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={getTableData}
                      >
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>IMAGE</strong>
                  </th>
                  <th>
                    <strong>NAME</strong>
                  </th>
                  <th>
                    <strong>EMAIL</strong>
                  </th>
                  <th>
                    <strong>DATE OF BIRTH</strong>
                  </th>
                  <th>
                    <strong>PHONE NUMBER</strong>
                  </th>
                  <th>
                    <strong>ACTION</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {users?.map((item) => (
                  <tr>
                    <td>
                      <img src={item.image} width={70} height={70} />{" "}
                    </td>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{moment(item?.dateOfBirth).format("DD/MM/YYYY")} </td>
                    <td>{item.phoneNumber}</td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="info light"
                          className="light sharp btn btn-info i-false"
                        >
                          {svg1}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <Link
                              to="user-details"
                              onClick={() => saveIdInLocalStorage(item._id)}
                              style={{ color: "grey" }}
                            >
                              View Details
                            </Link>
                          </Dropdown.Item>
                          {item.isBlocked ? (
                            <Dropdown.Item onClick={() => onUnblock(item._id)}>
                              {" "}
                              Enable
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item onClick={() => onBlock(item._id)}>
                              Disable
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {users?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
            {pageCount > 1 && (
              <div className="card-footer clearfix">
                <ReactPaginate
                  pageCount={pageCount}
                  forcePage={currentPage}
                  previousLabel={<span aria-hidden="true">&laquo;</span>}
                  nextLabel={<span aria-hidden="true">&raquo;</span>}
                  breakLabel={"....."}
                  marginPagesDisplayed={2}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"page-item active"}
                  onPageChange={(selected) => {
                    setCurrentPage(selected.selected);
                  }}
                />
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
      {loader && <Spinner />}
    </div>
  );
}
