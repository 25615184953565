import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Card, Table, Col } from "react-bootstrap";
import { checkAutoLogin, getUserEvents } from "../../services/AuthService";
import Spinner from "../loader/Spinner";
import moment from "moment";
export default function UserDetails(props) {
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const [loader, setLoader] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [events, setEvents] = useState([]);
  const limit = 10;
  useEffect(() => {
    setLoader(true);
    getUserEvents(currentPage, limit)
      .then((response) => {
        setEvents(response.data.data.event);
        const total = response.data.data.total;
        setLoader(false);
        setPageCount(Math.ceil(total / limit));
        console.log(Math.ceil(total / limit), " page count");
        console.log(response.data.data, " table data ");
        console.log(response.data.data.event, " table data ");
      })
      .catch((error) => {
        console.log(error.response, "helooooooooo");
        if (error.response.data.statusCode === 400) {
          localStorage.clear("userDetails");
          props.history.push("/login");
        }
      });
  }, [currentPage]);

  return (
    <div>
      <div className="page-titles">
        <h4>User's Events Detail</h4>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="users-management">Users</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link>User's Events Detail</Link>
          </li>
        </ol>
      </div>
      <Col>
        <Card>
          <Card.Header>
            <Card.Title>My Events</Card.Title>
          </Card.Header>
          <Card.Body style={{ paddingTop: "2px" }}>
            <Table responsive>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>EVENT IMAGE</strong>
                  </th>
                  <th>
                    <strong>EVENT NAME</strong>
                  </th>
                  <th>
                    <strong>EVENT DATE</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {events?.map((item) => (
                  <tr>
                    <td>
                      <img src={item.eventId?.image} width={70} height={70} />
                    </td>
                    <td>{item.eventId?.eventName}</td>
                    <td>
                      {moment(item.eventId?.eventDate).format("DD-MM-YYYY")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {events?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
            {pageCount > 1 && (
              <div className="card-footer clearfix">
                <ReactPaginate
                  pageCount={pageCount}
                  forcePage={currentPage}
                  previousLabel={<span aria-hidden="true">&laquo;</span>}
                  nextLabel={<span aria-hidden="true">&raquo;</span>}
                  breakLabel={"....."}
                  marginPagesDisplayed={2}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"page-item active"}
                  onPageChange={(selected) => {
                    setCurrentPage(selected.selected);
                  }}
                />
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
      {loader && <Spinner />}
    </div>
  );
}
