import React, { useState, useEffect } from "react";
import PageTitle from "../layouts/PageTitle";
import { Link } from "react-router-dom";
import { Dropdown, Card, Table, Col } from "react-bootstrap";
import {
  blockUser,
  getAllHosts,
  getHosts,
  saveHostIdInLocalStorage,
  unblockUser,
} from "../../services/AuthService";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Spinner from "../loader/Spinner";
import swal from "sweetalert";

export default function EventHosts(props) {
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const [loader, setLoader] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [hosts, setHosts] = useState([]);
  const [search, setSearch] = useState("");
  const limit = 10;

  function getHostData() {
    setLoader(true);
    getAllHosts(currentPage, limit, search)
      .then((response) => {
        // console.log(response, "user data response");
        setHosts(response.data.data.users);
        const total = response.data.data.total;
        // console.log(total, "total.....");
        setLoader(false);
        setPageCount(Math.ceil(total / limit));
        // console.log(limit, "limit");
      })
      .catch((error) => {
        console.log(error.response, "helooooooooo");
        if (error.response.data.statusCode === 400) {
          localStorage.clear("userDetails");
          props.history.push("/login");
        }
      });
  }

  function onBlock(userId) {
    // console.log(userId, "user id");

    blockUser(userId)
      .then((response) => {
        swal("User Blocked Successfully.", { icon: "success" });
        getHostData();
      })
      .catch((error) => {
        // console.log(error.response.data.message ,"Login error")
        // setApiError(error.response.data.message);
        if (error.response.data.statusCode === 400) {
          localStorage.clear("userDetails");
          props.history.push("/login");
        }
      });
  }
  function onUnblock(userId) {
    // console.log(userId, "user id");
    unblockUser(userId)
      .then((response) => {
        swal("User Unblocked Successfully.", { icon: "success" });
        getHostData();
      })
      .catch((error) => {
        // console.log(error.response.data.message ,"Login error")
        // setApiError(error.response.data.message);
        if (error.response.data.statusCode === 400) {
          localStorage.clear("userDetails");
          props.history.push("/login");
        }
      });
  }

  useEffect(() => {
    getHostData();
  }, [currentPage]);
  useEffect(() => {
    if (search.length > 0) {
      getHostData();
    } else {
      getHostData();
    }
  }, [currentPage, search]);
  return (
    <div>
      {/* {loader && loading} */}
      <PageTitle activeMenu="Event Hosts" motherMenu="Event Hosts" />
      <Col>
        <Card>
          <Card.Header>
            <div className="d-flex justify-content-between align-items-center">
              <div className="col-8" style={{ flexGrow: 1 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="input-group border bg-white input-group-sm"
                    style={{ borderRadius: "8px" }}
                  >
                    <input
                      style={{
                        paddingBottom: "25px",
                        paddingTop: "25px",
                        borderRadius: "10px",
                        fontSize: "14px",
                      }}
                      type="text"
                      name="table_search"
                      className="form-control float-right border-0"
                      placeholder="Search"
                      // onKeyDown={(e) => {
                      //   // console.log(e.key);
                      //   if (e.key === "Enter") {
                      //     getHostData();
                      //     // setCurrentPage(0);
                      //   }
                      // }}
                      onChange={(e) => {
                        setSearch(e.target.value);
                        // getHostData();
                      }}
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={getHostData}
                      >
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <thead style={{ color: "black" }}>
                <tr>
                  <th className="width80">
                    <strong>IMAGE</strong>
                  </th>
                  <th className="width80">
                    <strong>NAME</strong>
                  </th>
                  <th>
                    <strong>EMAIL</strong>
                  </th>
                  <th>
                    <strong>DATE OF BIRTH</strong>
                  </th>
                  <th>
                    <strong>PHONE NUMBER</strong>
                  </th>
                  <th className="text-center">
                    <strong>No. OF EVENT CREATED</strong>
                  </th>
                  <th>
                    <strong>ACTION</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {hosts?.map((item) => (
                  <tr>
                    <td>
                      <img src={item.user.image} width={70} height={70} />
                    </td>
                    <td>{item.user.name}</td>
                    <td>{item.user.email}</td>
                    <td>
                      {moment(item.user.dateOfBirth).format("DD/MM/YYYY")}
                    </td>
                    <td >{item.user.phoneNumber}</td>
                    <td className="text-center">{item.totalEvents}</td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="info light"
                          className="light sharp btn btn-info i-false"
                        >
                          {svg1}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <Link
                              to="event-details"
                              onClick={() =>
                                saveHostIdInLocalStorage(item.user._id)
                              }
                              style={{ color: "grey" }}
                            >
                              View Events
                            </Link>
                          </Dropdown.Item>
                          {item.user.isBlocked ? (
                            <Dropdown.Item
                              onClick={() => onUnblock(item.user._id)}
                            >
                              Enable
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item
                              onClick={() => onBlock(item.user._id)}
                            >
                              Disable
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {hosts?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
            {pageCount > 1 && (
              <div className="card-footer clearfix">
                <ReactPaginate
                  pageCount={pageCount}
                  forcePage={currentPage}
                  previousLabel={<span aria-hidden="true">&laquo;</span>}
                  nextLabel={<span aria-hidden="true">&raquo;</span>}
                  breakLabel={"....."}
                  marginPagesDisplayed={2}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"page-item active"}
                  onPageChange={(selected) => {
                    setCurrentPage(selected.selected);
                  }}
                />
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
      {loader && <Spinner />}
    </div>
  );
}
